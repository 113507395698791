<template>
<div class="loginPage">
    <header style="display: flex; justify-content: flex-end; align-items: center;">
        <div class="left">
            <van-icon name="cross" size=".5rem" @click="goBack()" />
        </div>
    </header>
    <div class="container">
        <h2>
            Hello,
            <br>
            Welcome to SlotGen
        </h2>
        <form class="form">
            <div class="formItem"><input type="number" placeholder="Enter phone number">
                <!---->
            </div>
            <div class="formItem"><input type="password" placeholder="Enter password">
                <!---->
            </div>
        </form>
        <div class="button gray" style="display: flex; justify-content: center; align-items: center;">
            Login
        </div>
        <div class="footer" style="display: flex; justify-content: center; align-items: center;"><span class="poa">Forgot password?</span>
            <div class="create">Create New Account</div>
            <div class="arrow"></div>
        </div>
    </div>
    <!---->
</div>
</template>

<script>
export default {
    name: 'Login',
    methods: {
        goBack() {
            this.$store.commit("setPageTransition", "back");
            this.$router.push({
                name: "Home"
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.loginPage {
    padding: 0 0.41666667rem;
    width: 9.16666667rem;
    height: 100%;
    background: #1e1f2d;
    z-index: 20;

    header {
        padding-bottom: 1.66666667rem;
        padding-top: 0.83333333rem;
    }

    .container {
        padding: 0 0.30555556rem;

        h2 {
            font-size: .61111111rem;
            font-weight: 900;
            line-height: .83333333rem;
            color: #fff;
        }

        .form {
            margin-top: 1.11111111rem;

            .formItem {
                position: relative;
                border-bottom: 0.02777778rem solid #545467;

                input {
                    width: 100%;
                    border: none;
                    outline: none;
                    height: 1.11111111rem;
                    margin-top: 0.55555556rem;
                    color: #fff;
                    background: inherit;
                }
            }
        }

        .button {
            height: 1.25rem;
            border-radius: 0.63888889rem;
            margin-top: 1.66666667rem;
            color: #fff;
            font-size: .48148148rem;
            font-weight: 900;
            background: #d8d8d8 linear-gradient(90deg, #e601fe, #7902ff);
        }

        .button.gray {
            background: hsla(0, 0%, 100%, .2);
            color: #818189;
        }

        .footer {
            position: relative;
            color: #e601fe;
            font-size: .38888889rem;
            margin-top: 0.625rem;
            margin-bottom: 2rem;
            text-align: center;

            .poa {
                position: absolute;
                right: 0;
                top: -3.14814815rem;
            }

            .create {
                text-decoration: underline;
            }

            .arrow {
                margin: 0 0 0 0.12037037rem;
                background-image: url(../assets/icon.png);
                background-position: -2.59259259rem -1.66666667rem;
                width: 0.14814815rem;
                height: 0.25925926rem;
                background-size: 5.66666667rem 5.37037037rem;
            }

        }


    }

}

input {
    font-size: .44444444rem;
}
</style>
